import { React, useState, useEffect, useContext } from 'react';
import { useSectionCursor, useCursorHandlers, CursorContext } from "../general/Cursor";
import { IoCalendarClear } from "react-icons/io5";
import Countdown from 'react-countdown';
import logo from "../../assets/logo.png";
import styled from "styled-components";
import { colors } from "../../assets/colors";

const LogoImage = styled.img`
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  object-fit: contain;
`;

const RSVP = styled.button`
  display: flex;
  text-align: center;
  cursor: none;
  border-radius: 18px;
  font-size: 1.1vw;
  color: ${colors.white};
  background-color: ${colors.red};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 2px;
  padding: 10%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -1vh;
  right: 13.8vw;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.65);
  color: ${colors.red};
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

const Toggle = styled.div`
  position: fixed;
  bottom: 15%;
  left: 0%;
  display: flex;
  flex-direction: column;
  z-index: 50;

  @media (max-width: 1024px) {
    display: none; /* Hide on tablet and smaller screens */
  }
`;

const DemoDayToggle = styled.div`
  width: 140px;
  height: 60px;
  display: flex;
  padding-right: 30%;
  align-items: center;
  justify-content: end; /* Centers the icon */
  font-weight: bold; /* Makes the icon appear bolder */
  color: white;
  background-color: rgba(246, 45, 11, 0.92);
  border-radius: 0 1rem 1rem 0; /* Only top-right and bottom-right corners rounded */
  text-decoration: none;
  transition: margin-left 0.3s, border-radius 0.3s;
  margin-left: -100px;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.2);
  
  &:hover {
    margin-left: -90px;
  }

  @media (max-width: 1024px) {
    display: none; /* Hide on tablet and smaller screens */
  }
`;

const CountdownContainer = styled.div`
  position: fixed;
  bottom: 2.2vh;
  left: 1.5vw;
  background-color: rgba(255, 255, 255, 0.85); 
  padding: 1.5vw;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  max-width: 180px;
  max-height: 180px;
  border: 0.2vw solid ${colors.red}; 
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.3);
  font-size: 2vw;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 1024px) {
    display: none; /* Hide on tablet and smaller screens */
  }
`;

function DemoCountdown() {
  const [showDemoCard, setShowDemoCard] = useState(true);
  const cursorHandlers = useSectionCursor({color: colors.black});
  const normalCursorHandlers = useCursorHandlers();
  const { setCursor } = useContext(CursorContext); 

  useEffect(() => {
    const savedState = sessionStorage.getItem('showDemoCard');
    if (savedState === 'false') {
      setShowDemoCard(false);
    }
  }, []);
  
  // Save to sessionStorage whenever showDemoCard state changes
  useEffect(() => {
    sessionStorage.setItem('showDemoCard', showDemoCard);
  }, [showDemoCard]);

  const handleClose = () => {
    setShowDemoCard(false);
    setCursor({ active: false, color: colors.black, type: 'default' }); // Reset to main circle
};

  return (
    <>
      {!showDemoCard && (
        <Toggle {...cursorHandlers}>
          <DemoDayToggle {...normalCursorHandlers} onClick={() => setShowDemoCard(true)}>
            <IoCalendarClear size={28}/>
          </DemoDayToggle>
        </Toggle>
      )}
      {showDemoCard && <CountdownContainer {...cursorHandlers}>
            <CloseButton {...normalCursorHandlers} onClick={handleClose}>×
            </CloseButton>
            <div style={{  display: 'flex', justifyContent: 'center', gap: '16px', alignItems: 'center', paddingBottom: '2%'  }}>
              <LogoImage src={logo} />
              <RSVP {...normalCursorHandlers} onClick={() => window.open('https://www.eventbrite.com/e/momentum-fall-2024-demo-day-tickets-1061505043159?aff=oddtdtcreator', '_blank')} >RSVP</RSVP>
            </div>
            <div style={{ fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center', marginBottom: '0.5vw' }}> 
                <span style={{ color: colors.red }}>Fall 2024</span> Demo Day Countdown
            </div>
            <Countdown
                date={new Date(2024, 10, 18, 18, 0, 0)}
                renderer={({ days, hours, minutes, seconds }) => (
                    <div style={{ fontSize: '0.8vw', fontWeight: 'bold', lineHeight: '1.2' }}> 
                        <div>{days} Days {hours} Hours</div>
                        <div>{minutes} Minutes {seconds} Seconds</div>
                    </div>
                )}/>
      </CountdownContainer>}
    </>
  );
}

export default DemoCountdown;