import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import Nav from "../components/general/Nav";
import Hero from "../components/joinus/Hero";
import JoinUs from "../components/joinus/JoinUs";
import ToSupport from "../components/joinus/ToSupport";
import SupportUs from "../components/supportus/Intro";
import DemoCountdown from "../components/countdown/DemoCountdown";

function Join() {
  // 👇️ redirect to external URL
  // window.location.replace('https://join.slack.com/t/tx-momentum/shared_invite/zt-1vx6ooddh-golkeZydGNRjdXcZeVKDxg');

  return (
    <CursorContextProvider>
      <Cursor />
      <DemoCountdown />
      <Hero />
      <JoinUs />
      <ToSupport />
      <Nav color={0} home={1} />
    </CursorContextProvider>
  );
}

export default Join;
