import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

// Import the necessary components for ACH payments
import {
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";

function Payment({ amount }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch("https://bf459bgne4.execute-api.us-east-1.amazonaws.com/prod/config")
      .then(async (r) => {
        const { publishableKey } = await r.json();
        console.log(publishableKey);
        setStripePromise(loadStripe(publishableKey));
      });
  }, []);

  useEffect(() => {
    fetch("https://bf459bgne4.execute-api.us-east-1.amazonaws.com/prod/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_method_types: ["card", "us_bank_account"],
        amount: amount,
      }),
    })
      .then(async (result) => {
        var { clientSecret } = await result.json();
        console.log(clientSecret);
        setClientSecret(clientSecret);
      });
  }, [amount]);

  return (
    <div style={{}}>
      {/* <h1 style={{cursor: "default"}}>Please enter your payment information</h1> */}
      {clientSecret && stripePromise && (
        <Elements style={{cursor: "default"}}stripe={stripePromise} options={{ clientSecret }}>
          {/* Add the LinkAuthenticationElement */}
          <LinkAuthenticationElement style={{ cursor: 'default' }}/>
          {/* Modify the CheckoutForm to include ACH */}
          <CheckoutForm style={{cursor: "default"}} includeAch={true} />
        </Elements>
      )}
    </div>
  );
}
export default Payment;