import styled from "styled-components";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";
import { useCursorHandlers } from "../general/Cursor";

const SectionContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  max-width: 100%;
`;

const ContentContainer = styled.div`
  text-align: center;
  color: ${colors.white};
`;

const Heading = styled.h1`
  font-size: 2em;
  font-weight: 300;
  margin-bottom: 1em;
`;

const SubHeading = styled.h2`
  font-size: 1.5em;
  font-weight: 300;
  margin-bottom: 1em;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  margin-bottom: 1em;
`;

const PriceBox = styled.div`
  background-color: ${colors.white};
  padding: 0.5em 1em;
  border-radius: 10px;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PriceAmount = styled.span`
  font-size: 1.5em;
  font-weight: bold;
  color: ${colors.red};
`;

const PriceDescription = styled.span`
  font-size: 0.6em;
  font-weight: normal;
  color: ${colors.black};
`;

const Note = styled.p`
  font-size: 0.8em;
  color: ${colors.white};
  margin-top: 0.5em;
`;

const EmphasisText = styled.h2`
  font-size: 2em;
  font-weight: black;
  color: ${colors.red};
  margin-top: 1em;
`;

const SubText = styled.p`
  font-size: 1.5em;
  font-style: italic;
  color: ${colors.white};
  margin-top: 0.5em;
`;
const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 1em;
  color: ${colors.black};
`;
function SupportUs() {
  const cursorHandlers = useSectionCursor({ color: colors.white });
  const normalCursorHandlers = useCursorHandlers();
  return (
    <div>
      <SectionContainer>
        <ContentContainer>
          <Heading>
            Since Texas Momentum's founding, we have survived off member dues{" "}
            <span style={{ color: colors.red }}>alone.</span>
          </Heading>
          <PriceContainer>
            <div>
              <PriceBox>
                <PriceAmount>$200</PriceAmount>
                <PriceDescription>per startup</PriceDescription>
              </PriceBox>
            </div>
            <div>
              <PriceBox>
                <PriceAmount>$35</PriceAmount>
                <PriceDescription>per intern</PriceDescription>
              </PriceBox>
              <Note>yes, they paid to work!</Note>
            </div>
          </PriceContainer>
          <EmphasisText>This semester, that all changes.</EmphasisText>
          <SubText>We are taking a leap of faith.</SubText>
        </ContentContainer>
      </SectionContainer>
    </div>
  );
}

export default SupportUs;
