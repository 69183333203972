import styled from "styled-components";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillMail,
  AiFillSlackSquare,
  AiOutlineClose,
} from "react-icons/ai";
import { colors } from "../../assets/colors";
import { Link } from "react-router-dom";
import { useState } from "react";

const ButtonContainer = styled.div`
  & {
    z-index: 99;
    height: 3.5vmax;
    width: 4vmax;
    background-color: transparent;

    display: flex;
    padding-left: 0.45vmax;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 2.5vmax;
    overflow: hidden;
    @media screen and (max-width: 500px) {
      height: 6vmax;
      width: 5vmax;
      font-size: 3.5vmax;
      justify-content: center;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const SocialsLink = styled.a`
  transition: all 0.2s;
  color: ${(props) => props.color || colors.black};
  &:hover {
    color: ${colors.red};
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const OverlayContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: default !important;
  color: ${colors.black};
  z-index: 100001;
`;

// TODO fix socials links
const socials = {
  Slack: {
    link: "https://join.slack.com/t/tx-momentum/shared_invite/zt-1vx6ooddh-golkeZydGNRjdXcZeVKDxg",
    icon: AiFillSlackSquare,
  },
  Instagram: {
    link: "https://www.instagram.com/txmomentum/",
    icon: AiFillInstagram,
  },
  LinkedIn: {
    link: "https://www.linkedin.com/company/txmomentum",
    icon: AiFillLinkedin,
  },
  Email: { link: "mailto:team@txmomentum.org", icon: AiFillMail },
};

function SocialButton(props) {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleSlackClick = () => {
    setShowOverlay(true);
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
  };

  return (
    <>
      {props.name === "Slack" ? (
        <>
          <SocialsLink
            onClick={handleSlackClick}
            color={props.color ? props.color : colors.black}
          >
            <ButtonContainer
              style={{
                display: props.visible ? "none" : "flex",
                backgroundColor: "transparent",
              }}
              {...props}
            >
              <IconContainer>{socials[props.name]["icon"]()}</IconContainer>
            </ButtonContainer>
          </SocialsLink>
          {showOverlay && (
            <Overlay onClick={handleOverlayClose}>
              <OverlayContent onClick={(e) => e.stopPropagation()}>
                {/* <CloseButton onClick={handleOverlayClose}> buggy cuz cursor weird
                  X 
                </CloseButton> */}
                {window.innerWidth < 500 ? (
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdecjQVwDF_iVbfa1VAIJfoNhU_lLJGY7QB9IRMR4U2lyurWA/viewform?embedded=true" width="350" height="400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                ) : (
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdecjQVwDF_iVbfa1VAIJfoNhU_lLJGY7QB9IRMR4U2lyurWA/viewform?embedded=true" width="640" height="697" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                )}
              </OverlayContent>
            </Overlay>
          )}
        </>
      ) : (
        <SocialsLink
          href={socials[props.name]["link"]}
          target="_blank"
          color={props.color ? props.color : colors.black}
        >
          <ButtonContainer
            style={{
              display: props.visible ? "none" : "flex",
              backgroundColor: "transparent",
            }}
            {...props}
          >
            
            <IconContainer>{socials[props.name]["icon"]()}</IconContainer>
          </ButtonContainer>
        </SocialsLink>
      )}
    </>
  );
}

export default SocialButton;
