import Payment from "../components/stripe/Payment";
import { useState } from "react";
import StripeStyle from "../components/stripe/StripeStyle.css";
import { colors } from "../assets/colors";
import logo from "../assets/logo.png";
import styled from "styled-components";
import StripeMotivation1 from "../assets/StripeMotivation1.png"
import StripeMotivation2 from "../assets/StripeMotivation2.png"
import SocialButton from "../components/general/SocialButton";


const TitleContainer = styled.div`
  color: ${colors.black};
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: auto;
  max-height: 80px;
  margin: 0;
  padding: 0;
`;

const MotivationImage = styled.img`
  width: 52%; 
  max-width: 800px;
  display: block; 
`;
const SupportTitle = styled.p`
    font-family: ReadexPro, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 4vmin;
    font-weight: 600;
    @media screen and (max-width: 500px) {
      font-size: 5vmin;
    }
`;

const SocialContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 500px) {
      width: 100%;
      justify-content: center;
    }
`;

const Stripe = () => {
  const [amount, setAmount] = useState("50.00");

  const handleAmountChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(input)) {
      setAmount(input);
    }
  };

  return (
    <div style={{ cursor: "auto", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'  }}>
      <div style={{ position: 'relative', cursor: "auto", display: 'flex', border: '1px solid', borderRadius: 35, borderColor: colors.black, width: '95vw', height: '95vh', boxSizing: 'border-box' }}>
        <div style={{ cursor: "default", flex: 1, display: 'flex', flexDirection: 'column',  cursor: "auto", margin: 0, padding: 0, height: '100%' }}>
          <TitleContainer>
            <LogoImage style={{cursor: "default",}} src={logo} />
            <SupportTitle  style={{
            color: colors.black, cursor: "default" }}>Support Momentum</SupportTitle>
          </TitleContainer>
          <div style={{cursor: 'default', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80%'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MotivationImage style={{ cursor: "default", marginRight: '-2%'}} src={StripeMotivation2} />
              <MotivationImage style={{ cursor: "default", marginLeft: '-2%'}} src={StripeMotivation1} />
            </div>
            <div style={{ cursor: "default", marginBottom: '2%', textAlign: 'center',  borderTop: `6px solid ${colors.red}`, borderBottom: `6px solid ${colors.red}`, fontSize: '24px',  
              fontWeight: 'bold', color: colors.red, marginTop: '20px', padding: '40px 0', }}>
              Thank you for funding <br />
              Momentum’s Fall 2024 Program!
            </div>
            <SocialContainer>
              {/* <SocialButton
                  name="Slack"
                /> */}
              <SocialButton
                  name="Instagram"
                />
              <SocialButton
                  name="LinkedIn"
                />
              <SocialButton
                  name="Email"
                />
            </SocialContainer>
            <div style={{marginTop: '-2%', padding: 0, cursor: 'default', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',fontSize: '15px',  
              fontWeight: '600', letterSpacing: '2px'}}>
              <h3 style={{ color: colors.red, cursor: 'default', marginRight: '5px' }}>TEXAS</h3>
              <h3 style={{ cursor: 'default', marginRight: '1%' }}>MOMENTUM</h3>
            </div>
          </div>
        </div>
        <div style={{
          borderLeft: `1px solid ${colors.black}`,
          margin: 0,
          padding: 0,
          height: "100%",
        }} />
        <div style={{ cursor: "default", flex: 1, marginLeft: '20px', marginRight: '20px', overflowY: 'auto',}}>
          <h3 style={{ cursor: "default" }}>Payment Information</h3>
          <label style={{ marginBottom: "4px", fontSize: "14px", color: colors.black, display: "block" }}>
          Contribution Amount
          </label>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: 0, padding: 0 }}>
            <span style={{ marginRight: "5px", color: colors.black, paddingBottom: "12px", fontSize: "0.8em" }}>$</span>
            <input
              type="text"
              placeholder="Amount"
              value={amount}
              onChange={handleAmountChange}
              style={{
                cursor: "auto",
                padding: "12px",
                fontSize: "14px",
                borderRadius: "6px",
                border: "0.8px solid #d1d5db",  // Smaller border size
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",  // Smooth transitions
                width: "100%",           // Ensure input takes full width of the parent
                boxSizing: "border-box",  // Include padding and border in width
                outline: "none",          // Remove default outline
              }}
              onFocus={(e) => {
                e.target.style.borderColor = "#3b82f6"; // Custom blue border on focus
                e.target.style.boxShadow = "0 0 0 3px rgba(59, 130, 246, 0.3)"; // Slightly lighter shadow effect
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#d1d5db"; // Reset border to grey on blur
                e.target.style.boxShadow = "none";      // Remove shadow on blur
              }}
            />
            
          </div>
          <div style={{ cursor: 'default', marginTop: '-2%',  display: 'flex', justifyContent: 'center'}}>
          <button style={{cursor: 'pointer', backgroundColor: colors.red, marginRight: '1%'}} onClick={() => {setAmount("100.00")}}>
            <span style={{ cursor: 'pointer'}} id="button-text">
              $100
            </span>
          </button>
          <button style={{cursor: 'pointer', backgroundColor: colors.red, marginRight: '1%'}} onClick={() => {setAmount("250.00")}}>
            <span style={{ cursor: 'pointer' }} id="button-text">
              $250
            </span>
          </button>
          <button style={{cursor: 'pointer', backgroundColor: colors.red, marginRight: '1%' }} onClick={() => {setAmount("500.00")}}>
            <span style={{ cursor: 'pointer' }} id="button-text">
              $500
            </span>
          </button>
          <button style={{cursor: 'pointer', backgroundColor: colors.red }} onClick={() => {setAmount("1000.00")}}>
            <span style={{ cursor: 'pointer' }} id="button-text">
              $1000
            </span>
          </button>
          </div>
          <hr style={{
            marginTop: "16px",
            border: "none",
            borderTop: "1px solid #e5e7eb",  // Light gray color for the divider
            width: "100%",                   // Full width divider
          }} />
        <Payment key={Math.round(parseFloat(amount) * 100)} amount={Math.round(parseFloat(amount) * 100)} />
      </div>
    </div>
  </div>

  );
};

export default Stripe;
