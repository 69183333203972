import React from "react";
import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Companies from '../components/portfolio/PrevCompanies';
import CompanyPage from '../components/portfolio/CompanyPage';
import Countdown from 'react-countdown';


function App() {

	return (
		<CursorContextProvider>
			<Cursor />
				<Routes>
        			<Route path="/" element={<Home />} />
        			<Route path="/portfolio" element={<Companies />} />
        			<Route path="/portfolio/:name" element={<CompanyPage />} />
      			</Routes>
		</CursorContextProvider>
		
	);
}

export default App;