import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles.css";
import "./assets/fonts.css";

import App from "./pages/App";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Team from "./pages/Team";
import Apply from "./pages/Apply";
import Join from "./pages/Join";
import CompanyPage from './components/portfolio/CompanyPage';
import Hidden from "./pages/Hidden";
import Support from "./pages/Support";
import { Cursor, CursorContextProvider, useCursorHandlers } from "./components/general/Cursor";
// import Projects from "./Pages/Projects";
// import About from "./Pages/About";
import ScrollToTop from "./components/general/ScrollToTop";
// import BDT from "./Pages/BDT";
// import Team from "./Pages/Team";
// import Application from "./Pages/Application";
import PageNotFound from "./components/general/PageNotFound";
import Stripe from "./pages/Stripe";
import Completion from "./components/stripe/Completion";
// import ClientProjects from "./Pages/ClientProjects"
// import Clients from "./Pages/Clients";
import Countdown from 'react-countdown';
const rootElement = document.getElementById("root");


ReactDOM.render(
  <StrictMode>

    {/* <div style={{
      position: 'fixed',
      bottom: '20px',
      left: '20px',
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
      fontFamily: 'sans-serif',
      fontSize: '18px',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    }}>
      Demo day countdown:
      <Countdown date={new Date(2024, 10, 31)} />
    </div> */}
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:name" element={<CompanyPage />} />
        <Route path="/team" element={<Team />} />
        <Route path="/join-us" element={<Join />} />
        <Route path="/support-us" element={<Support />} />
        <Route path="/hidden" element={<Hidden/>}/>
        {/* <Route path="/apply" element={<Apply />} /> */}
        {/* <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/BDT" element={<BDT />} />
        <Route path="/team" element={<Team />} />
        <Route path="/application" element={<Application/>} />
        <Route path="/applicants" element={<Application/>} />
        <Route path="/clients" element={<Clients/>} />
        <Route path="/client-projects" element={<ClientProjects/>} /> */}
        <Route path="*" element={<PageNotFound/>} />
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/completion" element={<Completion />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>,
  rootElement
);